import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/logo512.png" className="App-logo" alt="CDCPR" />
      </header>
    </div>
  );
}

export default App;
